import {
  CURRENCIES_ARRAY,
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE, FEDEX_PACKAGING_TYPES, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE
} from "../../../../staticData/staticVariables";
import {Express} from "./Express";
const moment = require('moment')
const _ = require('lodash');

Express.prototype.setType = function (val) {
  switch (val) {
    case DHL_DELIVERY_SERVICE.name:
      this.express.type = DHL_DELIVERY_SERVICE.id
      break

    case FEDEX_DELIVERY_SERVICE.name:
      this.express.type = FEDEX_DELIVERY_SERVICE.id
      break

    case TNT_DELIVERY_SERVICE.name:
      this.express.type = TNT_DELIVERY_SERVICE.id
      break

    case NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name:
      this.express.type = NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id
      break
  }

}

Express.prototype.setId = function (val) {
  this.express.expressId = val;
}

Express.prototype.setWarehouse = function (val) {
  this.express.warehouse = val;
}

Express.prototype.setFedexPackingType = function (val) {
  this.express.fedexPackingType = val;
}

Express.prototype.setTrackingNumber = function (val) {
  this.express.trackingNumber = val;
}

Express.prototype.setSenderContactName = function (val) {
  this.express.senderContactName = val;
}

Express.prototype.setSenderPhone = function (val) {
  this.express.senderPhone = val;
}

Express.prototype.setDeliveryDate = function (val) {
  this.express.deliveryDate = val;
}

Express.prototype.setTimeCourierTo = function (val) {
  this.express.timeCourierTo = val;
}

Express.prototype.setTimeCourierFrom = function (val) {
  this.express.timeCourierFrom = val;
}

Express.prototype.setTimeNotAvailableTo = function (val) {
  this.express.timeNotAvailableTo = val;
}

Express.prototype.setTimeNotAvailableFrom = function (val) {
  this.express.timeNotAvailableFrom = val;
}

Express.prototype.setExpressDownloadFiles = function (val) {
  this.express.downloadFiles = val
}

Express.prototype.setExpressDownloadPhotos = function (val) {
  this.express.downloadPhotos = val
}

Express.prototype.setOrderPrice = function (val) {
  this.express.orderPrice = val
}

Express.prototype.setFreightCost = function (val) {
  this.express.freightCost = val
}

Express.prototype.setVisibleFreightCost = function (val) {
  this.express.visibleFreightCost = val
}

Express.prototype.setRegions = function (val) {
  this.express.regions = val
}

Express.prototype.setVisibleHSCode = function (val) {
  this.express.visibleHSCode = val
}

Express.prototype.setVatNumber = function (val) {
  this.express.vatNumber = val
}

Express.prototype.setVisibleVatNumber = function (val) {
  this.express.isVatNumberByCountry = val
}

Express.prototype.setExpressTrack = function (val) {
  this.express.expressTrack = val
}

Express.prototype.setOrderCountryType = function (val) {
  this.express.orderCountryType = val
}

Express.prototype.setVisibleAndIsVatNumber = function (val) {
  this.express.visibleVatNumber = val
  this.express.isVatNumberByCountry = val
}

Express.prototype.setExpressTypeTransaction = function ({type, id}) {
  this.express.typeTransaction = type
  this.express.typeTransactionId = id

  if(type !== '' && id !== ''){
    this.express.issetPaymentTransaction = true
  }
}

Express.prototype.setExpressUser = function (val) {
  if(val === ''){
    this.express.user = {}
    this.express.userId = ''
  } else {
    this.express.user = val
    this.express.userId = val.id
  }
}

Express.prototype.setExternalTrnInfo = function (val = false) {

  if(!val) return

  let contacts = val.payment_transaction_detail.payment_transaction_contact,
    personal = this.express.personal,
    delivery = this.express.delivery

  personal.setPersonalItem({
    personalName: contacts.full_name,
    personalPhone: contacts.phone ? contacts.phone.replace(/[^\d]/g, '') : '',
    personalEmail: contacts.email,
  })

  this.express.userId = val.user.id
  this.express.user = val.user


  delivery.setDeliveryItem({
    address: contacts.address_line_1?.substring(0,35),
    address2: contacts.address_line_2?.substring(0,35),
    city: contacts.city,
    region: contacts.state,
    country: contacts.countryInstance ? {
      id: contacts.countryInstance.id,
      label: contacts.countryInstance.name,
      code_iso_2: contacts.countryInstance.code_iso_2,
    } : '',
    zip: contacts.zip_code,
  })

  this.express.externalImportOrderCountry = contacts.countryInstance ? {
    id: contacts.countryInstance.id,
    label: contacts.countryInstance.name,
    code_iso_2: contacts.countryInstance.code_iso_2,
  } : null

}

Express.prototype.setImportOrderInfo = function (val = false) {
  if(!val) return

  let personal = this.express.personal,
    delivery = this.express.delivery

  personal.setPersonalItem({
    personalName: val.customer_name,
    personalPhone: val.original_phone ? val.original_phone.replace(/[^\d]/g, '') : '',
    personalEmail: val.original_email,
  })

  this.express.userId = val.user.id
  this.express.user = val.user

  delivery.setDeliveryItem({
    address: val.original_address_line_1?.substring(0,35),
    address2: val.original_address_line_2?.substring(0,35),
    city: val.original_city,
    region: val.original_state,
    country: val.country ? {
      id: val.country.id,
      label: val.country.name,
      code_iso_2: val.country.code_iso_2,
    } : '',
    zip: val.original_post_code,
  })

  this.express.externalImportOrderCountry = val.country ? {
    id: val.country.id,
    label: val.country.name,
    code_iso_2: val.country.code_iso_2,
  } : null

}

Express.prototype.setSenderInfo = function (user) {
  if(!user?.id) return

  let userInformation

  if(user?.contacts){
    userInformation = _.first(user?.contacts)
  } else {
    userInformation = user?.user_personal_contact
  }

  this.express.senderContactName = userInformation.user_full_name
  this.express.senderPhone = userInformation.phone

}

Express.prototype.setExpressItem = function (val, isAdmin = false) {

  this.express.expressObj = val

  let ex = this.express,
    products = this.express.products,
    personal = this.express.personal,
    delivery = this.express.delivery,
    packaging = this.express.packaging,
    item = val.expressOrderInstance,
    proform = val.proformData,
    proformEntities = item.proform_entities

  ex.proformDocument = val?.user_proform_document_package_file

  ex.needPayFromFastReport = item?.need_pay_from_fast_report || false

  ex.orderNotification = val?.orderNotification

  ex.historyOfChanged = item?.activityUUID

  ex.variantLabelPrice =  item?.delivery_service_type

  ex.payedInvoiceFeeAmount = item.payed_invoice?.fee_amount ? item.payed_invoice.fee_amount : 0
  ex.payedInvoiceNetAmount = item.payed_invoice?.net_cost_amount ? item.payed_invoice.net_cost_amount : 0

  ex.isCopy = item.is_copy

  if(item.npg && !Array.isArray(item.npg)) {
    ex.novaPoshtaAddress = item.npg
    ex.serviceType = ex.serviceTypes.department
  }

  if (ex.type === DHL_DELIVERY_SERVICE.id) {
    if (val?.order_express_country_types && val?.order_express_country_types[DHL_DELIVERY_SERVICE.id] &&
      val?.order_express_country_types[DHL_DELIVERY_SERVICE.id].length > 0) {
      ex.orderCountryTypesArray = val.order_express_country_types[DHL_DELIVERY_SERVICE.id]
    }

    this.setOrderCountryType(item.order_express_country_type ? item.order_express_country_type :
      ex.orderCountryTypesArray.length > 0 && ex.isCopy === 1 ? ex.orderCountryTypesArray[0]?.value : '' )
  }

  ex.userId = item.user_id
  ex.issetPaymentTransaction = item.issetPaymentTransaction
  ex.expressId = item.id
  ex.expressStatus = item.status
  ex.expressInvoiceStatus = item.payed_invoice ? 'completed' : 'in_progress'
  ex.shippingCost = item?.payed_invoice?.net_cost_amount ? '$' + item.payed_invoice.net_cost_amount : ''
  ex.fee = item?.payed_invoice?.fee_amount ? '$' + item.payed_invoice.fee_amount : ''
  ex.transactionNum = item.payment_transaction ? item.payment_transaction.transaction_id : ''
  ex.transactionPaymentSystem = item.payment_transaction ? item.payment_transaction.payment_system : ''
  ex.typeTransactionId = item.payment_transaction_id ? item.payment_transaction_id : ''
  ex.typeTransaction = item.issetPaymentTransaction ? item.issetPaymentTransaction : ''
  ex.user = item.user
  ex.warehouse = item.warehouse
  ex.trackingNumber = item.new_post_tracking_number ? item.new_post_tracking_number : ''
  ex.systemTrackingNumber = item.tracking_number ? item.tracking_number : ''

  ex.canUserSendFedexFromAddress = val.fedex_user_can_send_from_address
  if (!ex.canUserSendFedexFromAddress && ex.type === FEDEX_DELIVERY_SERVICE.id) {
    ex.sendType = ex.sendTypes.warehouse
  }

  if(item.order_sender_type === 'by_himself') ex.sendType = ex.sendTypes.sender

  ex.senderContactName = item.sender_contact_name ? item.sender_contact_name : ''
  ex.senderPhone = item.sender_phone_number ? item.sender_phone_number : ''
  ex.dropBallTestCert = item.drop_certification === 1
  ex.toxicSubstanceControlActCert = item.toxic_substance === 1

  ex.currencies = _.find(CURRENCIES_ARRAY, {id: item.recipient_currency_id})

  if(item.total_order_amount){
    ex.orderPrice = item.total_order_amount
    ex.gotOrderPriceFlag = true
  }

  if (item.freight_cost) {
    ex.freightCost = item.freight_cost
  }

  ex.deliveryDate = item.delivery_date ? moment(item.delivery_date).format('MM/DD/YY') : ''

  if(item.vat_number) {
    ex.visibleVatNumber = true
    ex.vatNumber = item.vat_number
  } else {
    ex.visibleVatNumber = false
    // ex.isVatNumberByCountry = false
  }
  ex.isVatNumberByCountry = item.need_vat

  if(item.available_courier_time !== null) {
    let courierTime = item.available_courier_time.split('|')
    ex.timeCourierFrom = moment(courierTime[0], 'HH:mm').format('HH:mm')
    ex.timeCourierTo = moment(courierTime[1], 'HH:mm').format('HH:mm')
  }
  if(item.not_available_courier_time !== null){
    let courierLanchTime = item.not_available_courier_time.split('|')
    ex.lunchBreak = true

    ex.timeNotAvailableFrom = moment(courierLanchTime[0], 'HH:mm').format('HH:mm')
    ex.timeNotAvailableTo = moment(courierLanchTime[1], 'HH:mm').format('HH:mm')
  }


  // this.setExpressDownloadFiles()
  if(val.files && !(Object.keys(val.files).length === 1 && _.has(val.files, 'vat_pdf')) ){
    val.files ? this.express.files.push(val.files) : false
  }


  //  Set delivery data
  delivery.setDeliveryItem({
    address: item.recipient_address,
    address2: item.recipient_additional_address ? item.recipient_additional_address : '',
    city: item.recipient_city,
    region: item.recipient_region,
    country: {
      id: item.recipient_country.id,
      label: item.recipient_country.name,
      code_iso_2: item.recipient_country.code_iso_2,
    },
    zip: item.recipient_zip_code,
  })

  // Set personal data
  personal.setPersonalItem({
    personalName: item.recipient_contact_name,
    // personalPhone: item.recipient_phone_number.replaceAll('-', ''),
    personalPhone: item.recipient_phone_number,
    personalEmail: item.recipient_email,
  })

  // Set packaging data
  packaging.setPackagingItem({
    name: item.proform_packaging,
    weight: item.proform_packaging.weight,
    height: item.proform_packaging.height,
    width: item.proform_packaging.width,
    length: item.proform_packaging['length'],
    insurance: item.insurance_amount !== null,
    insurancePrice: item.insurance_amount ? item.insurance_amount : '',
  })

  // Add count proform products with response data
  if(Object.keys(proform).length > 1){
    Object.keys(proform).map(() => {
      // if only one product
      if(Object.keys(proform).length === products.length) return
      this.addProduct(true)
    })
  }

  // set proform product data & selected value
  Object.keys(proform).map((item, index) => {
    products[index].setOrderProformItem(proform[item], item, isAdmin)
  })

  // set HS Codes in proform products
  products.map(item => {
    let entityItem = _.find(proformEntities, {id: parseInt(item.getProformEntityId())})
    if (entityItem) {
      item.setHSCode(entityItem['hs_code'])
    }
  })

  if(item['packaging_type']){
    this.setFedexPackingType({
      translation: _.find(FEDEX_PACKAGING_TYPES, {value: item['packaging_type']}).translate,
      value: _.find(FEDEX_PACKAGING_TYPES, {value: item['packaging_type']}).value
    })
  }

  item.promocodes.map((code, index) => {
    if(ex.promoCodes[index]) {
      ex.promoCodes[index].setCode(code?.promocode?.code)
    } else {
      this.addPromoCode()
      ex.promoCodes[index].setCode(code?.promocode?.code)
    }
  })

  this.setExpressTrack(item?.external_lot_number || '')
}
